import React, { FC, useState, useEffect, useRef } from 'react';
import { useWindowSize } from '@hooks';
import { PlanCard } from '@lesmills-international/components';
import Carousel from '@components/atoms/carousel/Carousel';
import { SlicePlaceholder } from '@components/atoms';
import { formatFitnessLevels, getSlideSpace, getSlidesPerViewForPlans } from '@services';
import {
  PlansCarouselSliceWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  CarouselWrapper,
  SlicePlaceholderWrapper,
} from './styles';
import { GET_PLAN_BY_NAME } from '../../../engagementGql/plans';
import { createEngagementClient } from '../../../../../../src/services/client';
import { addErrorInDatadogRum } from '../../../utils/utilities';

interface Props {
  content: {
    primary: {
      title: string;
      subtitle: string;
      fitnessLevel: string;
    };
    items: Array<{
      fitness_level: string;
      plan_name: string;
    }>;
  };
}

interface PlanContent {
  primary: {
    title: string;
    subtitle: string;
    fitnessLevel: string;
  };
  items: Array<{
    fitness_level: string;
    plan_name: string;
  }>;
}

const fetchPlanNames = (content: PlanContent): string[] => {
  const matchingPlans = content.items
    .filter((item) => item.fitness_level.includes(content.primary.fitnessLevel))
    .map((item) => item.plan_name)
    .slice(0, 2);

  return matchingPlans;
};
const PlansCarousel: FC<Props> = ({ content }) => {
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState(1);
  const [carouselSlidesSpace, setCarouselSlidesSpace] = useState(2);
  const [planCards, setPlanCards] = useState<JSX.Element[]>([]);
  const [loading, setLoading] = useState(true);

  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();

  // Separate effect for fetching plans
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      const planNames: string[] = fetchPlanNames(content);

      try {
        const planQueries = planNames.map((planName) =>
          createEngagementClient.query({
            query: GET_PLAN_BY_NAME,
            variables: { name: planName },
          })
        );

        const planResults = await Promise.all(planQueries);
        const plans = planResults.map((result) => result?.data?.getPlanByName).filter(Boolean);

        const albumCardArray = plans.map((plan, index) => (
          <PlanCard
            key={index}
            backgroundImg={
              plan.items[0].imageNoText ? plan.items[0].imageNoText : plan.items[0].image
            }
            title={plan?.items[0].displayMainTitle}
            equipmentIcons={plan?.items[0].equipment || []}
            fitnessLevel={formatFitnessLevels(plan?.items[0].fitnessLevel)}
            label={plan?.items[0].displayTagline}
            length={Math.round(plan.items[0].length / 7)}
            daysPerWeek={plan?.items[0].daysPerWeek}
            minPerDay={plan?.items[0].minutesPerDay}
            link={plan.items[0].link}
          />
        ));

        setPlanCards(albumCardArray);
      } catch (error) {
        addErrorInDatadogRum(error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, [content]);

  // Separate effect for carousel setup
  useEffect(() => {
    if (carouselRef.current && !loading) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = getSlidesPerViewForPlans(carouselWrapperWidth);
      const slideSpace = getSlideSpace(carouselWrapperWidth);

      // Add empty divs if we have fewer items than slides per view
      const updatedCards = [...planCards];
      if (updatedCards.length < slidesPerView) {
        for (let i = 0; i < slidesPerView - updatedCards.length; i++) {
          updatedCards.push(<div key={`empty-${i}`} />);
        }
        setPlanCards(updatedCards);
      }

      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlidesSpace(slideSpace);
    }
  }, [windowInnerWidth, loading, planCards]);

  return (
    <PlansCarouselSliceWrapper>
      <TitleWrapper>
        <Title>{content.primary.title}</Title>
        {content.primary.subtitle && <Subtitle>{content.primary.subtitle}</Subtitle>}
      </TitleWrapper>
      {loading ? (
        <SlicePlaceholderWrapper>
          <SlicePlaceholder height="160px" />
        </SlicePlaceholderWrapper>
      ) : (
        <CarouselWrapper ref={carouselRef} noRightPadding>
          <Carousel
            items={planCards}
            slidesPerView={carouselSlidesPerView}
            slidesPerGroup={carouselSlidesPerView}
            spaceBetween={carouselSlidesSpace}
            className="home--carousel--wrapper"
            alwaysShowArrowButton
          />
        </CarouselWrapper>
      )}
    </PlansCarouselSliceWrapper>
  );
};

export default PlansCarousel;
