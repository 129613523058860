import React, { FC, useContext } from 'react';
import { PageProps } from 'gatsby';
import styled from 'styled-components';
import { Layout, withPrivateRoute, HomeHeroLoading } from '@components/molecules';
import { userContext } from '@context';
import { Theme } from '@components/molecules/layout';
import { breakpoint } from '@themes';
import SliceZone from '@components/sliceZone';
import { useSurveyAnswers } from '@hooks';
import {
  Early_Tenure_Home_Page,
  Early_Tenure_Home_PageGreetings,
} from '../../../generated/prismicGraphql';

const HomepageWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
`;
const Container = styled.div`
  background-color: ${(props) => props.theme.colors.black};
  min-height: calc(100vh - 64px);
`;

const Title = styled.h4`
  color: ${(props) => props.theme.colors.lightishGrey};
  font-size: 35px;
  line-height: 42px;
  font-family: 'Inter SemiBold';
  margin: 56px 0 40px;
  padding: 0 1rem;

  ${breakpoint('sm')`
    font-size: 35px;
    line-height: 50px;
    padding: 0 2rem;
  `}

  ${breakpoint('md')`
    font-size: 35px;
    line-height: 42px;
    padding: 0 2rem;
  `}
  ${breakpoint('lg')`
    font-size: 35px;
    line-height: 42px;
    padding: 0 5rem;
  `}
`;

interface HomeEarlyTenureProps {
  prismicEarlyTenureHomePage: {
    data: Early_Tenure_Home_Page;
  };
}

const getGreeting = (greetings: Early_Tenure_Home_PageGreetings[]) => {
  const currentHour = new Date().getHours();
  const currentMinute = new Date().getMinutes();
  const currentTime = currentHour * 100 + currentMinute;
  return greetings.find((greeting) => {
    const startTime = greeting?.start_time ? parseInt(greeting.start_time.replace(':', ''), 10) : 0;
    const endTime = greeting?.end_time ? parseInt(greeting.end_time.replace(':', ''), 10) : 0;
    return currentTime >= startTime && currentTime <= endTime;
  });
};

const HomeEarlyTenure: FC<PageProps<HomeEarlyTenureProps>> = ({ data }) => {
  const content = data.prismicEarlyTenureHomePage.data;

  const { mySurveyAnswers, isLoading } = useSurveyAnswers();
  const { givenName } = useContext(userContext);

  const greeting = getGreeting(content.greetings || []);

  if (isLoading) {
    return <HomeHeroLoading />;
  }

  const fitnessLevelAnswers = mySurveyAnswers?.find(
    (answers) => answers.questionId === 'collectionName_onboarding_27'
  );

  const fitnessLevel = fitnessLevelAnswers?.answers?.find(
    (answer) => answer?.selected
  )?.displayText;

  const contentWithFitnessLevel = {
    ...content,
    body: content?.body?.map((slice) => ({
      ...slice,
      primary: {
        ...slice.primary,
        fitnessLevel,
      },
    })),
  };

  return (
    <Layout theme={Theme.Dark}>
      <Container>
        <HomepageWrapper>
          <Title>
            {greeting?.greeting_text}, {givenName}
          </Title>
          <SliceZone slices={contentWithFitnessLevel?.body} />
        </HomepageWrapper>
      </Container>
    </Layout>
  );
};

export default withPrivateRoute(HomeEarlyTenure);
