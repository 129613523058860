import React, { FC, useContext, Suspense, lazy, useEffect, useState } from 'react';
import { PageProps, graphql, navigate } from 'gatsby';
import { userContext } from '@context';
import { withPrivateRoute, HomeHeroLoading } from '@components/molecules';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { TIERS, SUBSCRIPTION_STATES } from '../../../constants';

const HomePersonalise = lazy(() => import('../homePersonalise'));
const HomeEarlyTenure = lazy(() => import('../homeEarlyTenure'));

interface HomePageRenderingProps {
  prismicNewWebHomepage: any;
  prismicEarlyTenureHomePage: any;
}

const HomePageRendering: FC<PageProps<HomePageRenderingProps>> = ({ data, ...props }) => {
  const { isLoading, lmodSubscription, tier } = useContext(userContext);
  const [isReady, setIsReady] = useState(false);
  const isTrialing = lmodSubscription?.state?.toLowerCase() === SUBSCRIPTION_STATES.TRIALING;
  const isBase = tier === TIERS.BASE;
  const { rtWebHomescreenEt } = useFlags();

  useEffect(() => {
    if (!isLoading && lmodSubscription) {
      setIsReady(true);
    }
  }, [isLoading, lmodSubscription]);

  if (!isReady) {
    return <HomeHeroLoading />;
  }

  if (isBase) {
    navigate('/explore');
  }

  if (!rtWebHomescreenEt) {
    return (
      <Suspense fallback={<HomeHeroLoading />}>
        <HomePersonalise data={{ prismicNewWebHomepage: data.prismicNewWebHomepage }} {...props} />
      </Suspense>
    );
  }

  // if user is trialing and not on base tier, show early tenure page
  const earlyTenure = isTrialing && !isBase;

  return (
    <Suspense fallback={<HomeHeroLoading />}>
      {earlyTenure ? (
        <HomeEarlyTenure
          data={{ prismicEarlyTenureHomePage: data.prismicEarlyTenureHomePage }}
          {...props}
        />
      ) : (
        <HomePersonalise data={{ prismicNewWebHomepage: data.prismicNewWebHomepage }} {...props} />
      )}
    </Suspense>
  );
};

export const query = graphql`
  query GetHomePageData($lang: String!) {
    prismicNewWebHomepage(lang: { eq: $lang }) {
      data {
        meta_description
        meta_title
        body {
          ... on PrismicNewWebHomepageDataBodyMyScheduledWorkouts {
            id
            slice_label
            slice_type
            primary {
              choose_a_plan_cta_label
              download_app_description
              empty_plan_title
              plans_page {
                url
              }
              recovery_day_description
              recovery_day_title
              title
            }
          }
          ... on PrismicNewWebHomepageDataBodyPrograms {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
              is_tall
            }
          }
          ... on PrismicNewWebHomepageDataBodyWorkoutVideoCarousel {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
            }
            items {
              video_id
            }
          }
          ... on PrismicNewWebHomepageDataBodyAlbumsCarousel {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
              aspect_ratio
              small_card
            }
            items {
              label
              title
              topic
              description
              detail_link
              duration_min
              duration_sec
              background_image {
                url
              }
              equipment_bench_step
              equipment_foam_roller
              equipment_massage_ball
              equipment_mat
              equipment_mini_trampolim
              equipment_resistance_band
              equipment_stationary_bike
              equipment_weights
            }
          }
          ... on PrismicNewWebHomepageDataBodyVideoSlim {
            id
            slice_type
            slice_label
            items {
              title
              type
              view_all_text
              view_all_link
            }
          }
          ... on PrismicNewWebHomepageDataBodyHomepageHeroCarousel {
            id
            slice_type
            slice_label
            primary {
              title
              view_all_text
              view_all_link
              aspect_ratio
            }
            items {
              prismic_content
              label
              title
              topic
              description
              detail_link
              duration_min
              duration_sec
              background_image {
                url
              }
              target_user
              equipment_bench_step
              equipment_foam_roller
              equipment_massage_ball
              equipment_mat
              equipment_mini_trampolim
              equipment_resistance_band
              equipment_stationary_bike
              equipment_weights
            }
          }
          ... on PrismicNewWebHomepageDataBodyHomepageFooter {
            id
            slice_type
            slice_label
            primary {
              footer_content
              footer_link_text
              footer_link
            }
          }
        }
      }
    }
    prismicEarlyTenureHomePage(lang: { eq: $lang }) {
      data {
        greetings {
          greeting_text
          start_time
          end_time
        }
        plan_greeting
        body {
          ... on PrismicEarlyTenureHomePageDataBodyEarlyTenureHeroCarousel {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              background_image {
                url
                alt
                gatsbyImageData
              }
              label
              title
              description
              link
            }
          }
          ... on PrismicEarlyTenureHomePageDataBodyPlansCarousel {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              fitness_level
              plan_name
            }
          }
          ... on PrismicEarlyTenureHomePageDataBodyEarlyTenureInterestBasedCollection {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              program_background_image {
                url
                alt
                gatsbyImageData
              }
              option
              collection_id
              program_label
              program_name
              program_subtitle
            }
          }
          ... on PrismicEarlyTenureHomePageDataBodyVideosLessThan20Mins {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              view_more_text
              collection_id
            }
          }
          ... on PrismicEarlyTenureHomePageDataBodyNoEquipment {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              view_more_text
              collection_id
            }
          }
          ... on PrismicEarlyTenureHomePageDataBodyArticles {
            slice_type
            primary {
              title
              subtitle
            }
            items {
              type
              title
              link
              background_image {
                url
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrivateRoute(HomePageRendering);
