import React, { FC, useState, useRef, useEffect } from 'react';
import { AlbumCard } from '@lesmills-international/components';
import { useWindowSize } from '@hooks';
import Carousel from '@components/atoms/carousel/Carousel';
import { getSlideSpace, getSlidesPerView, getSlidesPerGroup } from '@services';
import {
  AlbumsCarouselSliceWrapper,
  TitleWrapper,
  Title,
  Subtitle,
  CarouselWrapper,
} from './styles';
import { Early_Tenure_Home_Screen_AppBodyAlbums_Carousel } from '../../../generated/prismicGraphql';

interface AlbumCarouselContent extends Early_Tenure_Home_Screen_AppBodyAlbums_Carousel {
  primary: {
    title: string;
    subtitle?: string;
    fitnessLevel: string;
  };
  items: AlbumItem[];
}
interface AlbumItem {
  fitness_level: string | null;
  background_image: {
    url: string;
    alt?: string;
  };
  label: 'VIDEO' | 'APP' | 'COMMUNITY';
  title: string;
  description: string;
  link: string;
}
interface Props {
  content: AlbumCarouselContent;
}

const getFilteredPlansByFitnessLevel = (
  items: AlbumItem[],
  userFitnessLevel: string
): AlbumItem[] =>
  items.filter((item) => !item?.fitness_level || item.fitness_level.includes(userFitnessLevel));

const EarlytenureAlbumCarousel: FC<Props> = ({ content }) => {
  const [carouselSlidesPerView, setCarouselSlidesPerView] = useState(3);
  const [carouselSlidesPerGroup, setCarouselSlidesPerGroup] = useState(1);
  const [carouselSlidesSpace, setCarouselSlidesSpace] = useState(8);
  const [albumCards, setAlbumCards] = useState<JSX.Element[]>([]);
  const { fitnessLevel: userFitnessLevel } = content.primary;

  const carouselRef = useRef<HTMLDivElement>(null);
  const windowInnerWidth = useWindowSize();

  useEffect(() => {
    if (carouselRef && carouselRef.current) {
      const carouselWrapperWidth = carouselRef.current.clientWidth;
      const slidesPerView = getSlidesPerView(carouselWrapperWidth);
      const slidesPerGroup = getSlidesPerGroup(carouselWrapperWidth);
      const slideSpace = getSlideSpace(carouselWrapperWidth);

      const filteredPlansByFitnessLevel = getFilteredPlansByFitnessLevel(
        content.items,
        userFitnessLevel
      );

      const albumCardArray = filteredPlansByFitnessLevel.map((item, index) => (
        <AlbumCard
          key={index}
          label={item.label}
          title={item.title}
          description={item.description}
          backgroundImg={item.background_image.url}
          link={item.link}
          aspectRatio="portrait"
          simplifiedVideo
        />
      ));

      // Add empty divs if we have fewer items than slides per view
      if (albumCardArray.length < slidesPerView) {
        for (let i = 0; i < slidesPerView - content.items.length; i++) {
          albumCardArray.push(<div />);
        }
      }

      setCarouselSlidesPerView(slidesPerView);
      setCarouselSlidesPerGroup(slidesPerGroup);
      setCarouselSlidesSpace(slideSpace);
      setAlbumCards(albumCardArray);
    }
  }, [windowInnerWidth, content.items, userFitnessLevel]);

  return (
    <AlbumsCarouselSliceWrapper>
      <TitleWrapper>
        <Title>{content.primary.title}</Title>
        {content.primary.subtitle && <Subtitle>{content.primary.subtitle}</Subtitle>}
      </TitleWrapper>
      <CarouselWrapper ref={carouselRef} noRightPadding>
        <Carousel
          items={albumCards}
          slidesPerView={carouselSlidesPerView}
          slidesPerGroup={carouselSlidesPerGroup}
          spaceBetween={carouselSlidesSpace}
          className="home--carousel--wrapper"
          alwaysShowArrowButton
        />
      </CarouselWrapper>
    </AlbumsCarouselSliceWrapper>
  );
};

export default EarlytenureAlbumCarousel;
